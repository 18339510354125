import { useEffect, useState } from "react";
import useTranslation from "next-translate/useTranslation";
import styles from "./Navigation.module.css";
import { FaUser } from "react-icons/fa";
import { FaCog } from "react-icons/fa";
import { FaSignOutAlt } from "react-icons/fa";
import { FaBars } from "react-icons/fa";

import { useRouter } from "next/router";
import Link from "next/link";
import Head from "next/head";
// import dynamic from "next/dynamic";
import { FaTrophy } from "react-icons/fa";

import { signOut, useSignIn } from "../ducks/signIn";
import { useUser } from "../ducks/user";
import { useSiteData } from "../ducks/siteData";
import { websocket } from "../services/websocket";
import { signInStatus } from "../ducks/signIn";

// const UserAvatar = dynamic(() => import("../modules/UserAvatar"));
// const GameInProgress = dynamic(() => import("./GameInProgress"));
// const MobileMenuDrawer = dynamic(() => import("./MobileMenuDrawer"));
const Notifications = dynamic(() => import("./Notifications"));
const FriendChat = dynamic(() => import("../modules/friendChat/FriendChat"));
// const OtherLoginPopup = dynamic(() => import("../modules/OtherLoginPopup"));
// const FriendsNav = dynamic(() => import("./FriendsNav"));
// const ChallengesNav = dynamic(() => import("./ChallengesNav"));
// const ChallengesGuestNav = dynamic(() => import("./ChallengesGuestNav"));

import UserAvatar from "../modules/UserAvatar";
import GameInProgress from "./GameInProgress";
import MobileMenuDrawer from "./MobileMenuDrawer";
// import Notifications from "./Notifications";
// import FriendChat from "../modules/friendChat/FriendChat";
import OtherLoginPopup from "../modules/OtherLoginPopup";
import FriendsNav from "./FriendsNav";
import ChallengesNav from "./ChallengesNav";
import ChallengesGuestNav from "./ChallengesGuestNav";
import dynamic from "next/dynamic";

const SHOW_BLOG = false;

function NavLink({
  href,
  exact,
  activeClassName,
  children,
  prefetch = true,
  ...props
}) {
  const { pathname } = useRouter();
  const isActive = exact ? pathname === href : pathname.startsWith(href);

  if (isActive) {
    props.className += " " + activeClassName;
  }

  return (
    <Link {...props} href={href} prefetch={prefetch}>
      {children}
    </Link>
  );
}

export default function Navigation() {
  const { t } = useTranslation("common");

  const [userMenuActive, setUserMenuActive] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const { username, memberLoggedIn } = useUser();
  const { loading } = useSignIn();

  const { loadingBarVisible, gamePath } = useSiteData();

  const router = useRouter();

  const currentPath = router.pathname;
  const isShortNav = currentPath != "/"; //currentPath === gamePath || currentPath === "/analysis";

  useEffect(() => {
    if (!websocket) {
      signInStatus();
    }
  }, []);

  function renderUserMenu() {
    return (
      <div className={styles.userMenu}>
        <Link
          onClick={() => setUserMenuActive(false)}
          className={styles.userMenuItem}
          href={"/profile"}
        >
          <div className={styles.icon}>
            <FaUser />
          </div>
          {t("Profile")}
        </Link>
        <Link
          onClick={() => setUserMenuActive(false)}
          className={styles.userMenuItem}
          href={"/leagues"}
        >
          <div className={styles.icon}>
            <FaTrophy />
          </div>
          {t("Leagues")}
        </Link>
        <Link
          onClick={() => setUserMenuActive(false)}
          className={styles.userMenuItem}
          href={"/settings/0"}
        >
          <div className={styles.icon}>
            <FaCog />
          </div>
          {t("Settings")}
        </Link>
        <a
          className={styles.userMenuItem}
          onClick={() => {
            setUserMenuActive(false);
            signOut();
          }}
        >
          <div className={styles.icon}>
            <FaSignOutAlt />
          </div>
          {t("Sign out")}
        </a>
      </div>
    );
  }

  function renderMemberMenu() {
    return (
      <>
        {userMenuActive && (
          <div
            onClick={() => setUserMenuActive(false)}
            className={styles.menuBg}
          ></div>
        )}
        <div className={"select-none md:top-0"}>
          {userMenuActive && renderUserMenu()}
          <div className="flex items-center space-x-3 md:space-x-4">
            <Notifications />

            <ChallengesNav />

            <FriendsNav />

            <div
              onClick={() => setMobileMenuOpen(true)}
              className="flex bg-gray-200 rounded-full md:hidden active:opacity-60 hover:opacity-70"
            >
              <div className="flex -mr-1 cursor-pointer md:hidden">
                <div className="relative flex items-center justify-center text-gray-600 rounded-full w-9 h-9">
                  <FaBars />
                </div>
              </div>

              <div className="flex items-center space-x-2 cursor-pointer md:hover:opacity-70">
                <UserAvatar className="w-9 h-9" />
              </div>
            </div>

            <span className="hidden md:flex has-tooltip">
              <span className="tooltip">{username}</span>
              <div
                onClick={() => setUserMenuActive(true)}
                className="flex items-center space-x-2 cursor-pointer md:hover:opacity-70"
              >
                <UserAvatar className="w-9 h-9" />
              </div>
            </span>
          </div>
        </div>
      </>
    );
  }

  function renderGuestMenu() {
    return (
      <>
        <div className="flex items-center">
          <div className="hidden md:flex">
            <NavLink
              key="register"
              exact
              className={styles.register}
              activeClassName={styles.current}
              href={"/register"}
            >
              {t("Sign up")}
            </NavLink>
          </div>
          <NavLink
            key="signIn"
            exact
            className={styles.signIn}
            activeClassName={styles.currentSignIn}
            href={"/signIn"}
          >
            {t("Log in")}
          </NavLink>

          <ChallengesGuestNav />

          <div
            onClick={() => setMobileMenuOpen(true)}
            className="flex ml-3 cursor-pointer md:hidden"
          >
            <div className="relative flex items-center justify-center text-gray-600 bg-gray-200 rounded-full w-9 h-9 hover:bg-gray-300">
              <FaBars />
            </div>
          </div>
        </div>

        {/* <div className={"ml-auto md:hidden"}>
            <button
              onClick={() => setMobileMenuOpen(true)}
              className="btn btn-icon btn-icon-lg btn-goast"
            >
              <FaBars />
            </button>
          </div> */}
      </>
    );
  }

  function setHrefLang(locale, currentPath) {
    if (
      (currentPath === "/termsofservice" ||
        currentPath === "/termsofservice/" ||
        currentPath === "/privacypolicy" ||
        currentPath === "/privacypolicy/") &&
      locale !== "en"
    ) {
      return (
        <link
          rel="canonical"
          href={`https://www.chesshotel.com${currentPath}`}
        />
      );
    }

    const locales = [
      "en",
      "es",
      "fr",
      "pt",
      "sv",
      "ru",
      "it",
      "de",
      "af",
      "ar",
      "az",
      "be",
      "bg",
      "bn",
      "bs",
      "ca",
      "cs",
      "da",
      "el",
      "et",
      "fa",
      "fi",
      "gl",
      "gu",
      "he",
      "hi",
      "hr",
      "hu",
      "hy",
      "id",
      "is",
      "ja",
      "ka",
      "kk",
      "kn",
      "ko",
      "lt",
      "lv",
      "ml",
      "mn",
      "mr",
      "nl",
      "nb",
      "pl",
      "ps",
      "pt-PT",
      "ro",
      "sk",
      "sl",
      "sr",
      "sw",
      "th",
      "tk",
      "tr",
      "uk",
      "ur",
      "uz",
      "vi",
      "zh-CN",
      "zh-TW",
    ];

    return locales.map((locale) => {
      if (locale == "en") {
        <link
          rel="alternate"
          href={`https://www.chesshotel.com${currentPath}`}
          hrefLang="x-default"
        />;
      } else {
        return (
          <link
            rel="alternate"
            hrefLang={locale}
            href={`https://www.chesshotel.com/${locale}${currentPath}`}
            key={locale}
          />
        );
      }
    });
  }

  const isGame = currentPath === gamePath;

  return (
    <>
      <MobileMenuDrawer
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
      />

      <OtherLoginPopup />
      <div
        className={`sticky md:static z-30 top-0 flex items-center border-b border-b-altgrey-100 bg-white 
      pl-7 lg:pl-12 pr-5 lg:pr-12
      ${isGame ? "hidden md:flex" : ""}`}
      >
        <Head key="navigation">{setHrefLang(router.locale, currentPath)}</Head>
        {
          <div
            className={
              loadingBarVisible
                ? styles.loading + " " + styles.loadingActive
                : styles.loading
            }
          />
        }

        <div className="flex items-center space-x-2 lg:mr-12 md:mr-2">
          <Link href={memberLoggedIn ? "/lobby" : "/"}>
            <div className={isShortNav ? "crown" : "crown md:logo"} />
          </Link>
          {/* {!memberLoggedIn && (
            <div className={"ml-auto md:hidden"}>
              <button
                onClick={() => setMobileMenuOpen(true)}
                className="btn btn-icon btn-icon-lg btn-goast"
              >
                <FaBars />
              </button>
            </div>
          )} */}
        </div>
        <div
          className={`bg-white
        ${
          isShortNav
            ? "flex justify-between top-16 w-full h-[58px]"
            : "md:flex w-full justify-between h-[58px] md:h-[100px]"
        }`}
        >
          <div className={styles.menu}>
            <NavLink
              exact={false}
              prefetch={false}
              activeClassName={styles.current}
              href="/lobby"
            >
              {t("Play")}
            </NavLink>
            <NavLink exact activeClassName={styles.current} href="/players">
              {t("Players")}
            </NavLink>
            <NavLink exact activeClassName={styles.current} href="/puzzle">
              {t("Puzzles")}
            </NavLink>
            {SHOW_BLOG && (
              <NavLink exact activeClassName={styles.current} href="/blog">
                {t("Blog")}
              </NavLink>
            )}
          </div>
        </div>

        {!loading && (
          <div className={`${styles.authMenu}`}>
            {memberLoggedIn ? renderMemberMenu() : renderGuestMenu()}
          </div>
        )}

        <FriendChat />
      </div>
      <GameInProgress />
    </>
  );
}
