import useTranslation from "next-translate/useTranslation";
import { RiSwordFill } from "react-icons/ri";
import { useChallenges, setChallengesMenuOpen } from "../ducks/challenges";
import ChallengesMenu from "./ChallengesMenu";

export default function ChallengesNav() {
  const { t } = useTranslation("common");

  const { challenges, challengesMenuOpen } = useChallenges();
  const challengeCount = challenges.length;

  function hideChallengeMenu(e) {
    e.stopPropagation();
    setChallengesMenuOpen(false);
  }

  return (
    <div
      className={`flex relative flex-col items-center`}
      onClick={() => setChallengesMenuOpen(!challengesMenuOpen)}
    >
      <div className="relative">
        <span className="cursor-pointer has-tooltip">
          <span className="tooltip">{t("Challenges")}</span>
          <div className="relative flex items-center justify-center text-xs text-gray-600 bg-gray-200 rounded-full w-9 h-9 hover:bg-gray-300 active:opacity-60">
            <div className="text-lg">
              <RiSwordFill />
            </div>

            {challengeCount > 0 && (
              <div className="badge">{challengeCount}</div>
            )}
          </div>
        </span>
      </div>
      {challengesMenuOpen && (
        <>
          <ChallengesMenu />
          <div
            className="hidden-overlay"
            onMouseUp={(e) => hideChallengeMenu(e)}
          />
        </>
      )}
    </div>
  );
}
