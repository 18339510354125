import React from "react";
import { IoClose } from "react-icons/io5";

export default function Popup({
  children,
  title,
  onClose = null,
  top = false,
}) {
  return (
    <div
      className={`fixed flex flex-col items-center  left-0 w-full h-full z-50 p-3
      ${top ? "top-[10%]" : "justify-center top-0"}`}
    >
      <div
        className="fixed top-0 w-full h-screen bg-black -z-10 opacity-20"
        onClick={onClose}
      ></div>
      <div className="relative flex flex-col w-full p-8 mt-0 bg-white rounded-md md:mt-30 md:w-2/3 md:max-w-xl">
        {onClose && (
          <button
            onClick={onClose}
            className="absolute right-5 top-5 btn btn-icon btn-icon-md btn-goast"
          >
            <div className="text-2xl">
              <IoClose />
            </div>
          </button>
        )}
        <div className="text-xl font-semibold md:text-2xl">{title}</div>
        <div className="mt-5">{children}</div>
      </div>
    </div>
  );
}
