import { useFriendChat } from "../ducks/friendChat";
import { useFriends } from "../ducks/friends";
import { FaUsers } from "react-icons/fa";
import { Friend } from "@chesshotel/types";
import { useRouter } from "next/router";
import { useUser } from "../ducks/user";
import useTranslation from "next-translate/useTranslation";

export default function FriendsNav() {
  const { t } = useTranslation("common");
  const { friendList, friendRequests } = useFriends();
  const { unreadMessages } = useFriendChat();

  const router = useRouter();

  const currentPath = router.pathname;
  const { memberLoggedIn } = useUser();

  let friendsOnline = 0;
  let unreadCount = 0;
  let badgeCount = 0;

  function checkFriends() {
    for (let key in unreadMessages) {
      unreadCount += unreadMessages[key];
    }

    badgeCount =
      currentPath !== "/friends" && friendRequests
        ? friendRequests.length + unreadCount
        : 0;

    friendList.forEach((friend: Friend) => {
      if (friend.online) {
        friendsOnline++;
      }
    });
  }

  if (memberLoggedIn) {
    checkFriends();
  }

  return (
    <span className="cursor-pointer has-tooltip">
      <span className="tooltip">{t("Friends")}</span>
      <div
        onClick={() => router.push("/friends")}
        className="relative flex items-center justify-center text-xs text-gray-600 bg-gray-200 rounded-full w-9 h-9 hover:bg-gray-300 active:opacity-60"
      >
        <div className="text-lg">
          <FaUsers />
        </div>
        {badgeCount > 0 && <div className="badge">{badgeCount}</div>}
        {friendsOnline > 0 && <div className="bg-green-400 avatar-online" />}
      </div>
    </span>
  );
}
