import React from "react";
import useTranslation from "next-translate/useTranslation";
import Popup from "../components/Popup";
import { initWebsocketConnect } from "../services/websocket";
import { setOtherLoginShow, useWsConnection } from "../ducks/wsConnection";

export default function OtherLoginPopup() {
  const { t } = useTranslation("common");

  const { otherLoginShow } = useWsConnection();

  if (!otherLoginShow) {
    return null;
  }

  return (
    <Popup onClose={null} title={t("Another login has been detected")}>
      <div className="flex items-center justify-center w-full pt-4 mt-4">
        <button
          className="btn btn-base btn-blue"
          onClick={() => {
            setOtherLoginShow(false);
            initWebsocketConnect();
          }}
        >
          {t("Reconnect")}
        </button>
      </div>
    </Popup>
  );
}
