import ChallengesMenu from "./ChallengesMenu";
import { useChallenges, setChallengesMenuOpen } from "../ducks/challenges";

export default function ChallengesGuestNav() {
  const { challenges, challengesMenuOpen } = useChallenges();
  const challengeCount = challenges.length;

  return (
    <>
      {challengesMenuOpen && challengeCount > 0 && (
        <div className="absolute flex justify-end w-full top-8 ">
          <ChallengesMenu />
          <div
            className="hidden-overlay"
            onMouseUp={(e) => setChallengesMenuOpen(false)}
          />
        </div>
      )}
    </>
  );
}
