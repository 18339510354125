import useTranslation from "next-translate/useTranslation";
import React, { Fragment } from "react";

import { FaChessRook } from "react-icons/fa";
import { FaClock } from "react-icons/fa";
// import { FaDiceSix } from 'react-icons/fa';
// import { FaPaperPlane } from 'react-icons/fa';
import { FaSignal } from "react-icons/fa";
import { FaUser } from "react-icons/fa";

import { FaCheck } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import { RiSwordFill } from "react-icons/ri";

// import { useStats } from "../ducks/stats";
import styles from "./styles/ChallengesMenu.module.css";
import {
  acceptDailyChallenge,
  acceptLiveChallenge,
} from "../modules/shared/challengeController";
import {
  ModeIds,
  TimeControl,
  // VARIANT_CHESS,
  VARIANT_CHESS960,
} from "@chesshotel/constants";
import {
  useChallenges,
  removeChallenge,
  setChallengesMenuOpen,
} from "../ducks/challenges";
import { formatTime, getModeIcon } from "../util/util";
import { api } from "../services/api";
import { ChallengeWs } from "../services/wsOutRoutes";
import { useUser } from "../ducks/user";

export default function ChallengesMenu() {
  const { t } = useTranslation("common");
  const { challenges } = useChallenges();
  const { isGuest } = useUser();
  // const { chess, chess960 } = useStats();

  function renderChallenges() {
    return challenges.map((challenge) => {
      const {
        time,
        inc,
        modeId,
        timeControl,
        turnLength,
        variantId,
        username,
      } = challenge;

      const displayName = username.startsWith("G*")
        ? username.substring(2)
        : username;

      const isDaily960 =
        variantId == VARIANT_CHESS960 && modeId == ModeIds.DAILY;
      return (
        <Fragment key={challenge.id}>
          <tr>
            <td className="font-bold">{displayName}</td>
            <td>{challenge.opponent.elo}</td>
            <td className="">
              {timeControl == TimeControl.DAILY
                ? t("days", { count: turnLength })
                : formatTime(time, inc)}
            </td>
            {isDaily960 ? (
              <td className="flex items-center space-x-1">
                <div>{getModeIcon(modeId)}</div> <div>960</div>
              </td>
            ) : (
              <td className="">{getModeIcon(modeId)}</td>
            )}
          </tr>
          <tr>
            <td colSpan={4} className="bg-white no-padding">
              <div className="flex flex-row w-full">
                <button
                  className="bg-green-400 dropdown-btn hover:bg-green-500"
                  onClick={() => {
                    if (challenge.timeControl == TimeControl.LIVE) {
                      acceptLiveChallenge(challenge);
                    } else {
                      acceptDailyChallenge(challenge);
                    }
                  }}
                >
                  <FaCheck />
                </button>
                <button
                  className="dropdown-btn bg-altgray-300 hover:bg-altgray-500"
                  onClick={() => {
                    ChallengeWs.declineChallenge(challenge);
                    api
                      .post("/challenge/delete", { slug: challenge.slug })
                      .then((res) => {
                        removeChallenge(challenge.id);
                      });
                  }}
                >
                  <FaTimes />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={4} className="bg-white divider">
              {/* <div style={{ height: "1px" }} className="bg-altgray-300"></div> */}
            </td>
          </tr>
        </Fragment>
      );
    });
  }

  if (challenges.length === 0 && !isGuest) {
    return (
      <div className="absolute z-30 p-4 text-sm translate-y-full bg-white border rounded-lg shadow -bottom-2 whitespace-nowrap border-slate-100">
        {t("No new challenges")}
      </div>
    );
  }
  return (
    <div className="absolute z-30 p-3 translate-y-full bg-white border rounded-lg shadow border-slate-100 -bottom-2">
      <table className={`${styles.lobbyTable} cursor-default`}>
        {challenges.length > 0 && (
          <>
            <colgroup>
              <col width="34%" />
              <col width="22%" />
              <col width="22%" />
              <col width="22%" />
            </colgroup>
            <thead>
              <tr>
                <th>
                  <div className="flex items-center space-x-1">
                    <div className="text-orange-400">
                      <FaUser />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="flex items-center space-x-1">
                    <div className="text-orange-400">
                      <FaSignal />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="flex items-center space-x-1">
                    <div className="text-orange-400">
                      <FaClock />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="flex items-center space-x-1">
                    <div className="text-orange-400">
                      <FaChessRook />
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>{renderChallenges()}</tbody>
          </>
        )}
      </table>
    </div>
  );
}
