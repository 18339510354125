import { useRouter } from "next/router";
import { useGamePlay } from "../ducks/gamePlay";
import { useSiteData } from "../ducks/siteData";
import Link from "next/link";
import { FaExclamationCircle } from "react-icons/fa";
import useTranslation from "next-translate/useTranslation";
export default function GameInProgress() {
  const router = useRouter();
  const currentPath = router.pathname;
  const { t } = useTranslation("common");

  const { gameEnded, gameId, gameIdSeen } = useGamePlay();
  const { gamePath } = useSiteData();
  const isGamePath = currentPath === gamePath;

  return (
    <>
      {!isGamePath && gameId && gameId == gameIdSeen && gameEnded === false && (
        <Link
          href={"/game"}
          className=" flex justify-center text-base items-center w-full p-2 bg-red-50 border-b
      text-red-500 hover:text-red-600 space-x-1.5 font-semibold"
        >
          <FaExclamationCircle />
          <div>{t("Game in progress")}</div>
        </Link>
      )}
    </>
  );
}
